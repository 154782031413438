(function ($) {
    let $fn = $(".comp_contact");

    if ($fn.length) {
        let $map = $fn.find("[data-map]");

        if($map.length) {
            $.getScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDL_qiIIGsrqG4-3Zf7zP6La57qleyQJ9M")
                .done(function () {
                    $map.nl_google_map('init');
                    $map.nl_google_map('markers_add');
                });
        }
    }
})(jQuery);